import { render, staticRenderFns } from "./DebugPlayerWatcher.vue?vue&type=template&id=2283cec9&scoped=true&"
import script from "./DebugPlayerWatcher.vue?vue&type=script&lang=js&"
export * from "./DebugPlayerWatcher.vue?vue&type=script&lang=js&"
import style0 from "./DebugPlayerWatcher.vue?vue&type=style&index=0&id=2283cec9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2283cec9",
  null
  
)

export default component.exports